<template>
  <div class="formfield-margin-wrapper col-12" :class="classByType()" v-if="field && !field.disabled">
    <FormHiddenFieldComponent v-if="parsedType=='hidden'" :label="field.label" type="hidden" :name="getName(field)" :value="field.value" @change="$emit('change')" :field="field" />
    <FormTextFieldComponent v-else-if="parsedType=='text_field'" :label="field.label" :type="field.field_type" :name="getName(field)" :value="field.text" @change="$emit('change')" :field="field" />
    <FormTextAreaFieldComponent v-else-if="parsedType=='text_area'" :label="field.label" :type="field.field_type" :name="getName(field)" :placeholder="field.default_text" :value="field.text" :updateUrl="field.update_url" @change="$emit('change')" :field="field" />
    <FormCheckBoxFieldComponent v-else-if="parsedType=='check'" :label="field.label" :type="field.field_type" :name="getName(field)" :value="field.value" @change="$emit('change')" :field="field" />
    <FormRadioFieldComponent v-else-if="parsedType=='radio'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" :subfields="field.fields" />
    <FormSelectFieldComponent v-else-if="parsedType=='select'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change', $event)" :field="field" />
    <FormListFieldComponent v-else-if="parsedType=='list'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" />
    <FormMultiSelectFieldComponent v-else-if="parsedType=='multiple_selection'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" @change="$emit('change')" :field="field" />
    <FormMultiSelect2FieldComponent v-else-if="parsedType=='multiple_selection2'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" @change="$emit('change')" :field="field" />
    <FormRadioButtonsTextHtmlFieldComponent v-else-if="parsedType=='radio2'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" />
    <FormTextFieldComponent v-else-if="parsedType=='text'" :label="field.label" type="text" :name="getName(field)" :value="field.text" @change="$emit('change')" :field="field" />
    <FormRadioToggleFieldComponent v-else-if="parsedType=='radio_toggle'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" />
    <FormRadioButtonsFieldComponent v-else-if="parsedType=='radio_buttons'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" />
    <FormMultipleButtonsFieldComponent v-else-if="parsedType=='multiple_buttons'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" />
    <FormRadioSwitchFieldComponent v-else-if="parsedType=='radio_switch'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value || field.val" @change="$emit('change')" :field="field" />
    <FormSelectCitySearchFieldComponent v-else-if="parsedType=='select_city_search'" :label="field.label" :type="field.field_type" :name="getName(field)" :options="field.options" :value="field.value" @change="$emit('change')" :field="field" />
    <FormDateFieldComponent v-else-if="parsedType=='datepicker'" :label="field.label" :name="getName(field)" :value="field.text" @change="$emit('change')" :field="field" />
    <FormMultiSelectButtonFieldComponent v-else-if="parsedType=='multiple_selection_button'" :label="field.label || field.title" :type="field.field_type" :name="getName(field)" :options="field.options" :field="field" @change="$emit('change')" />
    <FormLinkFieldComponent v-else-if="parsedType=='link'" :type="field.field_type" :field="field" />
    <FormReadOnlyTextFieldComponent v-else-if="parsedType=='readonly_text'" :field="field" />
    <FormCheckBoxSubFormFieldComponent v-else-if="parsedType=='checkbox_subform'" :label="field.label || field.title" :type="field.field_type" :name="getName(field)" :subfields="field.fields" :field="field" @change="$emit('change')" />
    <FormDescriptionComponent v-else-if="parsedType=='description'" :text="field.text" />
    <FormRatingComponent v-else-if="parsedType=='rating'" :field="field" @ratingChanged="$emit('ratingChanged', $event)"/>
    <FormSectionTitleComponent v-else-if="parsedType=='section_title'" :field="field" :title="field.title" />
    <FormFieldLabelOnlyComponent v-else-if="parsedType=='label_only'" :field="field" :text="field.text" />
  </div>
</template>
<script>
import FormTextFieldComponent from "@/components/form/FormTextFieldComponent";
import FormRatingComponent from "@/components/form/FormRatingComponent";
import FormDescriptionComponent from "@/components/form/FormDescriptionComponent";
import FormLinkFieldComponent from "@/components/form/FormLinkFieldComponent";
import FormDateFieldComponent from "@/components/form/FormDateFieldComponent";
import FormTextAreaFieldComponent from "@/components/form/FormTextAreaFieldComponent";
import FormCheckBoxFieldComponent from "@/components/form/FormCheckBoxFieldComponent";
import FormRadioFieldComponent from "@/components/form/FormRadioFieldComponent";
import FormRadioToggleFieldComponent from "@/components/form/FormRadioToggleFieldComponent";
import FormRadioButtonsFieldComponent from "@/components/form/FormRadioButtonsFieldComponent";
import FormRadioButtonsTextHtmlFieldComponent from "@/components/form/FormRadioButtonsTextHtmlFieldComponent";
import FormSelectFieldComponent from "@/components/form/FormSelectFieldComponent";
import FormListFieldComponent from "@/components/form/FormListFieldComponent";
import FormMultiSelectFieldComponent from "@/components/form/FormMultiSelectFieldComponent";
import FormMultiSelect2FieldComponent from "@/components/form/FormMultiSelect2FieldComponent";
import FormMultiSelectButtonFieldComponent from "@/components/form/FormMultiSelectButtonFieldComponent";
import FormHiddenFieldComponent from "@/components/form/FormHiddenFieldComponent";
import FormRadioSwitchFieldComponent from "@/components/form/FormRadioSwitchFieldComponent";
import FormSelectCitySearchFieldComponent from "@/components/form/FormSelectCitySearchFieldComponent";
import FormReadOnlyTextFieldComponent from "@/components/form/FormReadOnlyTextFieldComponent";
import FormCheckBoxSubFormFieldComponent from "@/components/form/FormCheckBoxSubFormFieldComponent";
import FormMultipleButtonsFieldComponent from "@/components/form/FormMultipleButtonsFieldComponent";
import FormSectionTitleComponent from "@/components/form/FormSectionTitleComponent";
import FormFieldLabelOnlyComponent from "@/components/form/FormFieldLabelOnlyComponent";

const TypesWithCol6 = ["text_field", "text", "check", "select", "rating", "radio"];

export default {
  name: 'form-field',
  components: {
    FormHiddenFieldComponent,
    FormTextFieldComponent,
    FormDescriptionComponent,
    FormLinkFieldComponent,
    FormReadOnlyTextFieldComponent,
    FormTextAreaFieldComponent,
    FormCheckBoxFieldComponent,
    FormRadioFieldComponent,
    FormSelectFieldComponent,
    FormMultiSelectFieldComponent,
    FormMultiSelect2FieldComponent,
    FormRadioButtonsFieldComponent,
    FormRadioToggleFieldComponent,
    FormRadioButtonsTextHtmlFieldComponent,
    FormRadioSwitchFieldComponent,
    FormSelectCitySearchFieldComponent,
    FormDateFieldComponent,
    FormMultiSelectButtonFieldComponent,
    FormCheckBoxSubFormFieldComponent,
    FormListFieldComponent,
    FormMultipleButtonsFieldComponent,
    FormRatingComponent,
    FormSectionTitleComponent,
    FormFieldLabelOnlyComponent
  },
  methods: {
    getName(field, idx) {
      return field.data_name || field?.update?.data_name || 'field_' + idx;
    },
    classByType() {
      const type = this.$props.type || this.field.type;
      if (this.$isDesktop() && this.field.desktopClass) {
        return this.field.desktopClass;
      }
      if (TypesWithCol6.includes(type) && !this.field.wide) {
        return "col-xl-6";
      }
      return "";
    },
    bgColorByType() {
      const bgColor = this.$props.bgColor || this.field.bgColor;
      if (bgColor && bgColor === "white") {
        return "bgColor-white";
      }
      return "";
    }
  },
  props: {
    field: {},
    type: String,
    bgColor: String
  },
  computed: {
    parsedType() {
      const type = this.$props.type || this.field.type;
      // if (type == 'radio2') return 'radio_buttons';
      return type;
    }
  }
}
</script>