<template>
  <div>
    <!--Newsletter subscribe open by event component -->
  </div>
</template>
<script>

export default {
  props: {
    delay: {
      type: Number,
      deafult: 2000
    }
  },
  data() {
    return {
      timeoutHandler: null
    }
  },
  mounted() {
  this.mouseLeaveHandler = (event) => {
    // Ensure the event fires only when the user exits from the top
    if (event.clientY <= 0) {
      // Check if popup is already open
      if (!this.$store.state.popupAlreadyShown && !document.body.classList.contains("popupisopen")) {
        this.$store.dispatch("showNewsletterSubscribe");
        this.$store.commit("setPopupShown");
      }
    }
  };

  // Attach event listener to window
  window.addEventListener("mouseout", this.mouseLeaveHandler);
},

unmounted() {
  if (this.mouseLeaveHandler) {
    window.removeEventListener("mouseout", this.mouseLeaveHandler);
  }
}


}
</script>