import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue';
import router from './router';
import store from './store';
import FormFieldComponent from "@/components/form/FormFieldComponent";
import mitt from 'mitt';
import responsive from './plugins/responsive';
import VueGtag from "vue-gtag";
import { GoogleLogin } from "vue3-google-login";  // Import GoogleLogin

// Your Google Client ID for OAuth
const clientId = "931116126849-a02pdtjqm80hiurr81p1vfoc9ji641to.apps.googleusercontent.com";  // Replace with your actual Google Client ID

const emitter = mitt();
const app = createApp(App);


app.config.globalProperties.emitter = emitter;
app.use(store)
    .use(responsive)
    .use(router)
    .use(VueGtag, {
        config: { id: "UA-29142731-1" }
    }, router)
    .use(createGtm({
        id: 'GTM-5H7CHJT',
        queryParams: {
            gtm_auth: 'LLNX_udhMcTw0isbit-J4A',
            gtm_preview: 'env-8'
        },
        defer: false,
        compatibility: false,
        nonce: '2726c7f26c',
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false,
    }))
    .use(GoogleLogin, { clientId: clientId })
    .directive('scroll', {
        inserted: function (el, binding) {
            const onScrollCallback = binding.value;
            window.addEventListener('scroll', () => onScrollCallback());
        }
    })
    .component('FormFieldComponent', FormFieldComponent)
    .mount('#app');
