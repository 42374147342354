<template>
  <div dir="rtl" class="newsletter_subscribe">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row">
        <div class="df36-l30-b f20-l30-m white " @click="$emit('close')">X</div>
        <div class="df36-l30-b f26-l30-m text-center flex-grow-1 white margin-top-20" v-html="data.title"></div>
      </div>
      <div class="f26-l30-m text-center flex-grow-1 white margin-top-20" v-html="data.subtitle"></div>
    </div>
    <div class="margin-top-50 d-flex flex-column align-items-center">
      <form class="formfield-round-border " :action="data.submit.url" ref="form" method="post">
        <FormFieldComponent :field="field" v-for="(field,idx) in data.form.fields" :key="field.data_name || 'field_' + idx" bgColor="white" />
        <ButtonComponent :caption="data.submit.label" clazz="margin-top-20" @click="onSubmit()" @keyup.enter="onSubmit()" variant="black"></ButtonComponent>
      </form>
      <div v-if="replyMessage" class="message">
        <p>{{ replyMessage }}</p>
    </div>
    </div>
  </div>
</template>
<script>
import {formSubmit} from '@/modules/configuration.js'
import FormFieldComponent from '@/components/form/FormFieldComponent.vue';
import ButtonComponent from "@/components/form/ButtonComponent";

export default {
  components: {
    FormFieldComponent,
    ButtonComponent,
  },
  props: {
    data: Object
  },
  data() {
    return {
      replyMessage: "",
    }
  },
  methods: {
    async onSubmit() {
      let form = this.$refs.form;
      let reply = await formSubmit(this, form);
      await this.$nextTick();
      // this.$emit("close");
      if (reply.success) {
        this.replyMessage = reply.subscribed; 
      }
      setTimeout(() => {
        this.$emit("close");
      }, 3000); // 10,000 ms = 10 seconds
    }
  },

}
</script>
<style>
.newsletter_subscribe {
  height: 360px;
  max-height: 530px;
  background: rgb(239, 61, 73);
  border-width: 0px;
  border-style: none;
  border-color: rgb(41, 43, 44);
  min-width: 780px;
  min-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 24px 0px;
  border-image: initial !important;
  width: 700px;
  top: 0px;
  left: 0px;
}
@media (max-width: 768px) {
  .newsletter_subscribe {
  height: 400px;
  max-height: 530px;
  background: rgb(239, 61, 73);
  border-width: 0px;
  border-style: none;
  border-color: rgb(41, 43, 44);
  min-width: 300px;
  min-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 24px 0px;
  border-image: initial !important;
  width: 300px;
  top: 0px;
  left: 0px;
}
}

</style>